<template lang="">
    <v-card>
        
        <s-crud
            :config="config"
            title="PRE Registro de Personal"
            :filter="filter"
            add
            edit
            @save="savePerson($event)"
        >
            <template v-slot:filter>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-date
                                label="Fecha Inicio"
                                v-model="filter.DateBegin"
                            ></s-date>
                        </v-col>
                        <v-col>
                            <s-date
                                label="Fecha Fin"
                                v-model="filter.DateEnd"
                            ></s-date>
                        </v-col>
                        <v-col>
                            <s-text
                                label="Documento"
                                number
                                v-model="filter.PrsDocumentNumber"
                            ></s-text>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot="props">
                <v-container>
                    <v-row >   
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-definition 
                                :def="1036" 
                                label="Tipo de Documento" 
                                v-model="props.item.TypePersonDocument" 
                            />
                        </v-col>                
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-text 
                                 @keyupEnter="txtDocumentSearch(props.item)"
                                label="Documento*" 
                                v-model="props.item.PrsDocumentNumber"
                                number />
                        </v-col> 
                        <v-spacer></v-spacer>
                        <v-col class="s-col-form" sm="3" md="3"  >
                            <s-date label="Fecha de Incripcion*" v-model="props.item.DateIncription" />
                        </v-col> 
                    </v-row>
                    <v-row>                      
                        <v-col>
                            <v-divider ></v-divider>
                            <br/></v-col>
                    </v-row>
                    <v-row>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-text label="Apellido Paterno*" v-model="props.item.PrpPaternalSurname" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-text label="Apellido Materno*"  v-model="props.item.PrpMaternalSurname"  />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-text label="Nombres*" v-model="props.item.PrpName"  />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-date label="Fecha de Nacimiento*"  v-model="props.item.PrpBirthDate" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-definition :def="1037" label="Genero*"  v-model="props.item.TypeSex" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-text label="Celular*"   v-model="props.item.Phone"/>
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-text label="Celular 2*"  v-model="props.item.PhoneTwo" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-definition :def="1378" label="Situacion*"  v-model="props.item.TypeSituation" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-definition :def="1062" label="Turno*"  v-model="props.item.TypeTurn" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-definition :def="1066" label="Grado de Intruccion*"  v-model="props.item.TypeInstructionDegree" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-definition :def="1376" label="Profesion*" v-model="props.item.TypeProfession" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-text label="Experiencia*"  v-model="props.item.Experience" />
                        </v-col>                       
                    </v-row>
                    <v-row>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-date label="F. Emision del Documento*"  v-model="props.item.DateIssuanseDocument" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-area  
                            label="Area de Postulacion*"  
                            :text="props.item.AreName"
                            v-model="props.item.AreID" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select
                                label="Zonas"
                                :items="itemsZons"
                                item-value="PrzID"
                                item-text="PrzDescription"
                                autocomplete
                                @input="zonChange($event)"
                                v-model="props.item.PrzID" 
                            ></s-select>                          
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                             <s-select
                                label="Sub Zonas"
                                :items="itemsSubZons"
                                item-value="PszID"
                                item-text="PszDescription"
                                autocomplete
                                v-model="props.item.PszID" 
                            ></s-select>
                        </v-col>                       
                    </v-row>
                    <v-row>                      
                        <v-col>
                            <v-divider ></v-divider>
                            <br/></v-col>
                    </v-row>
                    <v-row>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-date label="Fecha. Topico*"  v-model="props.item.TopicDate" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-definition  
                            label="Tipo de Diagnostico*"  
                             def="1398"
                            v-model="props.item.TypeTopicDiagnosis" />
                        </v-col>
                        <v-col class="s-col-form" sm="6" md="6">
                            <s-text
                                label="Observaciones Topico"
                                 
                                v-model="props.item.TopicObservation" 
                            ></s-text>                          
                        </v-col>
                                                
                    </v-row>
                    <v-row>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-text label="Nombre de Ultima Vacuna*"  v-model="props.item.NameLastVaccine" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-text label="Numero de Docis*" number v-model="props.item.NumDoceVaccine" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-date label="Fecha de Ultima Vacuna" v-model="props.item.DateLastVaccine"></s-date>                          
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-date label="F. Contrato*"  v-model="props.item.DateContrat" />
                        </v-col>                       
                    </v-row>
                    <v-row>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-date label="F. Induccion*"  v-model="props.item.DateInduction" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-definition  
                            label="Turno Induccion*"  
                             def="1399"
                            v-model="props.item.TypeTurnInduction" />
                        </v-col>
                        <v-col class="s-col-form" sm="3" md="3">
                            <s-select-definition
                                label="Asistencia de Induccion"
                                def="1400"
                                v-model="props.item.TypeInductionAssistance" 
                            ></s-select-definition>                          
                        </v-col>
                                                
                    </v-row>
                </v-container>
            </template>
            <template v-slot:Assist="{ row }">
                <v-btn x-small color="info" block outlined @click="clickAssist(row)">
                    Marcar
                </v-btn>
            </template>
        </s-crud>
    </v-card>
</template>
<script>
import SText from '../../../components/Utils/SText.vue'
import ServiceGenPerson from "@/services/General/PersonService";
import ServiceGenPersonZon from "@/services/General/PersonZonService";
import ServicePreRegister from "@/services/HumanResource/PreRegisterPerson";
 

export default {
    components: {
        SText
    },
    data() {
        return {
            config: {
                service: ServicePreRegister,
                model: {
                    PrpID : "ID"
                },
                headers: [
                    {text: "Asistencia", value: "Assist"},
                    {text: "ID", value: "PrpID"},
                    {text: "Tipo Documento", value: "TypePersonDocument"},
                    {text: "Numero Documento", value: "PrsDocumentNumber"},
                    {text: "F. Emision Doc", value: "DateIssuanseDocument"},
                    {text: "Fecha Inscripcion", value: "DateIncription"},
                    {text: "Apellido Paterno", value: "PrpPaternalSurname"},
                    {text: "Apellido Materno", value: "PrpMaternalSurname"},
                    {text: "Nombres", value: "PrpName"},
                    {text: "F. Nacimiento", value: "PrpBirthDate"},
                    {text: "Genero", value: "TypeSexName"},
                    {text: "Celular", value: "Phone"},
                    {text: "Celular 2", value: "PhoneTwo"},
                    {text: "Situación", value: "TypeSituationName"},
                    {text: "Turno", value: "TypeTurnName"},
                    {text: "Experiencia", value: "Experience"},
                    {text: "Grado Instruccion", value: "TypeInstructionDegreeName"},
                    {text: "Profesion", value: "TypeProfessionName"},
                    {text: "Area Postulacion", value: "AreName"},
                    // {text: "Area Postulacion", value: "AreID"},
                    {text: "Distrito", value: "PrzDescription"},
                    // {text: "Distrito", value: "PrzID"},
                    {text: "Zona", value: "PszDescription"},
                    // {text: "Zona", value: "PszID"}
                ]
            },
            filter:{
                PrsDocumentNumber: ""
            },
            person: {
                PrpID: 0,
                TypePersonDocument: 0,
                PrsDocumentNumber: "",
                DateIssuanseDocument: "",
                DateIncription: "",
                PrpPaternalSurname: "",
                PrpMaternalSurname: "",
                PrpName: "",
                PrpBirthDate: "",
                TypeSex: 0,
                Phone: "",
                PhoneTwo: "",
                Situation: "0",
                TypeTurn: 0,
                Experience: "",
                TypeInstructionDegree: 0,
                TypeProfession: 0,
                AreID: 0,
                PerSzoID: 0
            },
            itemsZons: [],
            itemsSubZons: []
        }
    },
    methods: {
        clickAssist(){

        },
        txtDocumentSearch(item){
 
            this.Clear(item);
            ServiceGenPerson.getNaturalPerson(item.PrsDocumentNumber, this.$fun.getUserID())
            .then((r) => {               
                if (r.status == 200) {
                    if (r.data != null) {
                        
                        const person = r.data;
                        console.log(person);
                        item.TypePersonDocument = person.GenPerson.TypePersonDocument;
                       // item.PrsDocumentNumber = person.GenPerson.PrsDocumentNumber;
                        item.DateIncription = person.DateIncription;
                        item.PrpPaternalSurname = person.NtpPaternalSurname;
                        item.PrpMaternalSurname = person.NtpMaternalSurname;
                        item.PrpName = person.NtpName;
                        item.PrpBirthDate = person.NtpBirthDate;
                        item.TypeSex = person.TypeSex;
                        
                        if (person.GenPerson.Telephones.length > 0) {
                            item.Phone = person.GenPerson.Telephones[0].PpnNumberPhone;
                            if (person.GenPerson.Telephones.length > 1) {
                                item.PhoneTwo = person.GenPerson.Telephones[1].PpnNumberPhone;
                            }
                        }
                        
                    console.log(item);
                    }else{
                        this.$fun.alert("Persona no encontrada","warning");
                    }
                }
            })          
        },       
        savePerson(item){

            if(item.PrsDocumentNumber.length < 8){
                this.$fun.alert("Numero de carateres", "warning");
                return;
            }
            if(item.PrsDocumentNumber.trim() == ""){
                this.$fun.alert("Complete Documento", "warning");
                return;
            }
            if(item.PrpPaternalSurname.trim() == ""){
                this.$fun.alert("Complete Documento", "warning");
                return;
            }
            if(item.PrpMaternalSurname.trim() == ""){
                this.$fun.alert("Complete Documento", "warning");
                return;
            }
            if(item.PrpName.trim() == ""){
                this.$fun.alert("Complete Nombre", "warning");
                return;
            }         
            item.save();
        },
        Clear(item){

            item.PrpPaternalSurname = "";
            item.PrpMaternalSurname = "";
            item.PrpName = "";

            item.Experience = "";
            item.PrzID = 0;
            item.PszID = 0;
            item.Phone = "";
            item.PhoneTwo = "";

        },
       zonChange(PszZonID){
            console.log(PszZonID);
            if(PszZonID != undefined ){
                 ServiceGenPersonZon.SubZonlist(PszZonID, this.$fun.getUserID())
                .then((r) => {               
                    if (r.status == 200) {
                        this.itemsSubZons = r.data;                       
                    }
                });
            }
       }
    },
     mounted() {             
        let search = '';
        ServiceGenPersonZon.list(search, this.$fun.getUserID())
        .then((r) => {               
            if (r.status == 200) {
                this.itemsZons = r.data;                    
            }
        });        
    },
}
</script>
<style lang="">
    
</style>
