import Service from "../Service";
const resource = "/general/personzon/";

export default {
    
    list(parameters, requestID) {
        return Service.post(resource + "list", {}, {
            params: { Search:parameters, requestID: requestID },
        });
    },
    SubZonlist(parameters, requestID) {
        return Service.post(resource + "SubZonlist", {}, {
            params: { PszZonID:parameters, requestID: requestID },
        });
    },
};